import { Env } from 'config/Env'
import {
  CreateDomoDbReq,
  CreateRationaleReq,
  UpdateDomoDbReq,
  UpdateRationaleReq,
  UpdateDomoDbPriorityReq,
  CreateSourceHospitalReq,
  UpdateSourceHospital,
  ReqBody,
  MapHospitalBody,
} from 'features/account/types'
import { DropDownEnvelope } from 'utils/types/DropDownEnvelope'
import { Envelope } from 'utils/types/Envelope'

import { HttpClient, ApiRequestTypes } from '../../../utils/apiService/httpService'
import {
  ActivityLogsResponse,
  Categories,
  Referral,
  QuestionRequest,
  AnswerList,
  HoldReferral,
  LostReferral,
  CardResponse,
  ReferralsGidview,
  PendingPatient,
  ReferralApproveRejectRequest,
  SexOffenderMatchBody,
  WaystarMatchRequest,
  ParentReferralApproveReject,
  QuestionAnswer,
  OutboundMessageRequest,
  MessagesFromSourceRequest,
  TaskListRequest,
  UpdateTaskListStatus,
  UnLockReferralData,
  MappedCommunitiesRequest,
  AddMappedCommunityRequest,
  CommunitiesForUnmatchedRequest,
  SubmitClinicalReviewParentDTO,
  SubmitClinicalReviewChildDTO,
  PVerifyMatchRequest,
  PVerifySubmitObject,
  AssignListResponse,
  AssignedUsersReq,
  DeleteAssignedUserReq,
  AssignedUserResponse,
  AddUsersReq,
  ActivityLogsRequest,
  ConfirmReadAlertRequest,
  InsuranceDetailReq,
  ReferralUpdatesResData,
  CommunityFaxReq,
  FaxStatusResponse,
} from '../types'

const TASK_LIST_URL = `${Env.REACT_APP_API_URL}Referral/api/TaskList`
const UPDATE_TASK_LIST_URL = `${Env.REACT_APP_API_URL}Referral/api/TaskList/UpdateTaskListStatus`

const IMPORT_REFERRAL_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/ImportReferral`
const ACTIVITY_LOG_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetActivityLog`
const SAVE_NEW_PARENT_CHILD_REFERRAL = `${Env.REACT_APP_API_URL}Referral/api/Referral/CreateReferral`
const UPDATE_CHILD_REFERRAL = `${Env.REACT_APP_API_URL}Referral/api/Referral/UpdateChildReferral`
const UPDATE_PARENT_REFERRAL = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/UpdateParentReferral`
const GET_CHILD_REFERRAL_DETAIL_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetReferralById`
const GET_PARENT_REFERRAL_DETAIL_URL = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/GetParentReferralById`
const SAVE_ATTACHMENTS_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/saveattachment`
const GET_REFERRAL_SOURCE_PDF_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetDocumentById`
const GET_ATTACHMENT_DOC_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetAttachmentDocumentById`
const GET_QUESTION_VIEW_URL = `${Env.REACT_APP_API_URL}Organization/api/FacilityCategory/GetCategoryDetailsById`
const SUBMIT_QUESTION_ANSWER_URL = `${Env.REACT_APP_API_URL}Organization/api/Answer/create`
const GET_QUESTION_ANSWER_URL = `${Env.REACT_APP_API_URL}Organization/api/Answer/GetQuestionAnswerById`
const HOLD_REFERRAL_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/HoldReferral`
const LOST_REFERRAL_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/LostReferral`
const REFERRAL_GRID_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetGroupStatus`
const REFERRAL_LIST = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetAllReferrals`
const DELETE_REFERRAL_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/DeleteReferral`
const DELETE_PARENT_REFERRAL_URL = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/DeleteParentReferral`
const GET_PATIENT_MATCH = `${Env.REACT_APP_API_URL}Referral/api/Pcc/GetPatientMatch`
const PENDING_PATIENT_SUBMIT = `${Env.REACT_APP_API_URL}Referral/api/Pcc/PendingPatient`
const REFERRAL_MANUAL_TRIGGERING = `${Env.REACT_APP_API_URL}Referral/api/Referral/ManualTriggerDbToPdf`
const COMMENT_LOG_URL = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetStatusLog`
const SEX_OFFENDER_LIST_URL = `${Env.REACT_APP_API_URL}Referral/api/SexOffender/GetSexOffenderList`
const SEX_OFFENDER_MATCH_URL = `${Env.REACT_APP_API_URL}Referral/api/SexOffender/SexOffenderMatch`
const REFERRAL_DENIAL_REASONS = `${Env.REACT_APP_API_URL}Referral/api/DenialReason/GetDenialReasons`
const GET_PAYER_LIST_URL = `${Env.REACT_APP_API_URL}Referral/api/WayStar/GetPayerLists`
const GET_PVERIFY_PAYER_LIST_URL = `${Env.REACT_APP_API_URL}Referral/api/PVerify/GetPayerLists`
const WAYSTAR_MATCH_REQUEST_URL = `${Env.REACT_APP_API_URL}Referral/api/WayStar/SubmitPayer`
const PVERIFY_MATCH_REQUEST_URL = `${Env.REACT_APP_API_URL}Referral/api/PVerify/SubmitPayer`
const DELETE_WAYSTAR_PROVIDER_URL = `${Env.REACT_APP_API_URL}Referral/api/WayStar/DeletePayer`
const DELETE_PVERIFY_PROVIDER_URL = `${Env.REACT_APP_API_URL}Referral/api/PVerify/DeletePayer`
const SUBMIT_WAYSTAR_URL = `${Env.REACT_APP_API_URL}Referral/api/WayStar/WayStarSubmit`
const SUBMIT_PVERIFY_URL = `${Env.REACT_APP_API_URL}Referral/api/PVerify/PVerifySubmit`
const WAYSTAR_VALIDATE = `${Env.REACT_APP_API_URL}Referral/api/WayStar/ValidatePayer`
const PVERIFY_VALIDATE = `${Env.REACT_APP_API_URL}Referral/api/PVerify/ValidatePayer`

const CHILD_REFERRAL_STATUS_UPDATE = `${Env.REACT_APP_API_URL}Referral/api/Referral/StatusUpdateChild`
const CHILD_REFERRAL_APPROVE_REJECT = `${Env.REACT_APP_API_URL}Referral/api/Referral/ApproveRejectChild`
const PARENT_REFERRAL_STATUS_UPDATE = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/StatusUpdateParent`
const PARENT_REFERRAL_APPROVE_REJECT = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/ApproveRejectParent`
const PARENT_REFERRAL_FACILITIES = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/GetReferralFacility`
const UPDATE_CLINICAL_REVIEW_STATUS = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/SubmitClinicalReviewParent`
const UPDATE_CLINICAL_REVIEW_STATUS_CHILD = `${Env.REACT_APP_API_URL}Referral/api/Referral/SubmitClinicalReviewChild`
const CLINICAL_REVIEW_FACILITIES = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/GetFacilityListClinicalReview`
const GET_ALL_ROUTES = `${Env.REACT_APP_API_URL}Referral/api/Route/GetRoutes`
const GET_ALL_REFERRAL_STATUS = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetStatus`
const INTEGRATION_FEATURES = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetIntegrationFeaturesById`
const OUTBOUNDCOMMUNICATION = `${Env.REACT_APP_API_URL}Referral/api/ReferralSourceCommunication/AddOutBoundCommunication`
const MESSAGE_LIST_FROM_SOURCE = `${Env.REACT_APP_API_URL}Referral/api/ReferralSourceCommunication/GetAllReferralSourceCommunication`
const CHILD_REFERRAL_STATUS_REVERSE = `${Env.REACT_APP_API_URL}Referral/api/Referral/StatusReverseCommunity`
const REVERSE_TO_CLINICAL_REVIEW = `${Env.REACT_APP_API_URL}Referral/api/Referral/StatusRevertClinical`
const GET_PCC_PATIENT_DEATILS = `${Env.REACT_APP_API_URL}Referral/api/Pcc/GetPatientDetailsById`

const GET_MOST_RECENT_DENIAL_REASON = `${Env.REACT_APP_API_URL}Referral/api/DenialReason/GetMostRecentDenialReason`

const GET_INTEGRATION_SOURCE_STATUS = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetIntegrationSourceStatus`
const GET_HOLD_REASONS = `${Env.REACT_APP_API_URL}Referral/api/HoldRationale/GetAll`
const GET_LOST_REASONS = `${Env.REACT_APP_API_URL}Referral/api/LostRationale/GetAll`

const GET_INSURANCE_DETAILS_BY_PATIENT_ID = `${Env.REACT_APP_API_URL}Referral/api/WayStar/GetInsuranceDetailsByPatientId`
const REFERRAL_UNLOCK = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/UnlockParentReferral`
const UNMAPPED_COMMUNITIES = `${Env.REACT_APP_API_URL}Referral/api/CommunityMapping/GetUnMappedCommunities`
const COMMUNITIES_UNMATCHED = `${Env.REACT_APP_API_URL}Referral/api/CommunityMapping/GetCommunityFacilityList`
const ADD_MAPPED_COMMUNITY = `${Env.REACT_APP_API_URL}Referral/api/CommunityMapping/SaveCommunityMapping`
const UPDATE_MAPPED_COMMUNITY = `${Env.REACT_APP_API_URL}Referral/api/CommunityMapping/EditCommunityMapping`
const MAPPED_COMMUNITIES = `${Env.REACT_APP_API_URL}Referral/api/CommunityMapping/GetMappedCommunities`
const ADD_HOLD_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/HoldRationale/Create`
const ADD_LOST_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/LostRationale/Create`

const UPDATE_LOST_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/LostRationale/Update`
const UPDATE_HOLD_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/HoldRationale/Update`

const DELETE_HOLD_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/HoldRationale/Delete`
const DELETE_LOST_RATIONALE = `${Env.REACT_APP_API_URL}Referral/api/LostRationale/Delete`
const REFERRAL_PDF_REGENERATION = `${Env.REACT_APP_API_URL}Referral/api/Referral/RegeneratePDF`
const ADD_DOMO_LABEL = `${Env.REACT_APP_API_URL}Referral/api/Domo/Create`

const UPDATE_DOMO_LABEL = `${Env.REACT_APP_API_URL}Referral/api/Domo/Update`

const DELETE_DOMO_LABEL = `${Env.REACT_APP_API_URL}Referral/api/Domo/Delete`
const FETCH_DOMO_DASHBOARD_LIST = `${Env.REACT_APP_API_URL}Referral/api/Domo/GetAll`
const UPDATE_DASHBOARD_PRIORITY = `${Env.REACT_APP_API_URL}Referral/api/Domo/UpdateDisplayOrder`
const REFERRAL_ASSIGNED_USERS_LIST = `${Env.REACT_APP_API_URL}Referral/api/ReferralLeadAgent/GetAssignedReferralLeadAgents`
const DELETE_LEAD_AGENTS = `${Env.REACT_APP_API_URL}Referral/api/ReferralLeadAgent/DeleteReferralLeadAgent`
const ADD_REFERRAL_USERS = `${Env.REACT_APP_API_URL}Referral/api/ReferralLeadAgent/AssignReferralLeadAgents`
const GET_ASSIGNED_USERS_COUNT = `${Env.REACT_APP_API_URL}Referral/api/ReferralLeadAgent/GetAssignedUsersCount`
const CONFIRM_READ_ALERT = `${Env.REACT_APP_API_URL}Referral/api/Referral/ConfirmReadAlert`
const GET_REFERRAL_UPDATES = `${Env.REACT_APP_API_URL}Referral/api/ReferralSourceCommunication/GetUpdatedXmlDetails`

const CREATE_SOURCE_HOSPITAL = `${Env.REACT_APP_API_URL}Referral/api/SourceHospital/Create`
const UPDATE_SOURCE_HOSPITAL = `${Env.REACT_APP_API_URL}Referral/api/SourceHospital/Update`
const DELETE_SOURCE_HOSPITAL = `${Env.REACT_APP_API_URL}Referral/api/SourceHospital/Delete`
const GET_ALL_SOURCE_HOSPITALS = `${Env.REACT_APP_API_URL}Referral/api/SourceHospital/GetAll`

const GET_UNMAPPED_SOURCE_HOSPITALS = `${Env.REACT_APP_API_URL}Referral/api/SourceHospitalMapping/GetUnmappedSourceHospitals`
const GET_MAPPED_SOURCE_HOSPITALS = `${Env.REACT_APP_API_URL}Referral/api/SourceHospitalMapping/GetMappedSourceHospitals`
const MAP_SOURCE_HOSPITAL = `${Env.REACT_APP_API_URL}Referral/api/SourceHospitalMapping/MapSourceHospital`
const COMMUNITY_FAX = `${Env.REACT_APP_API_URL}Referral/api/Referral/HasCommunityFax`
const PARENT_FAX = `${Env.REACT_APP_API_URL}Referral/api/ParentReferral/HasParentFax`

export const importReferral = (data: FormData): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](IMPORT_REFERRAL_URL, data)

export const getActivityLogs = (data: ActivityLogsRequest): Promise<ActivityLogsResponse> =>
  HttpClient[ApiRequestTypes.post](ACTIVITY_LOG_URL, data)

export const saveNewParentOrChildReferral = (data: Referral): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SAVE_NEW_PARENT_CHILD_REFERRAL, data)
export const updateChildReferral = (data: Referral): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_CHILD_REFERRAL, data)
export const updateParentReferral = (data: Referral): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_PARENT_REFERRAL, data)

export const getChildReferralDetail = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_CHILD_REFERRAL_DETAIL_URL}?patientId=${id}`)

export const getParentReferralDetail = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_PARENT_REFERRAL_DETAIL_URL}?patientId=${id}`)

export const saveNewAttachments = (data: FormData): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SAVE_ATTACHMENTS_URL, data)

export const getReferralSourcePdf = (
  patientId: number,
  isHighlightedPdf: boolean,
): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_REFERRAL_SOURCE_PDF_URL, { patientId, isHighlightedPdf })

export const getAttachmentDoc = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_ATTACHMENT_DOC_URL}?id=${id}`)

export const getQuestionView = (
  data: QuestionRequest,
): Promise<{ data: { categoriesDetailDTOs: Categories[] } }> =>
  HttpClient[ApiRequestTypes.post](GET_QUESTION_VIEW_URL, data)
export const submitQuestion = (data: AnswerList): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SUBMIT_QUESTION_ANSWER_URL, data)

export const getQuestionAnswers = (data: QuestionAnswer): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_QUESTION_ANSWER_URL, data)

export const setHoldReferral = (data: HoldReferral): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](HOLD_REFERRAL_URL, data)

export const setLostReferral = (data: LostReferral): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](LOST_REFERRAL_URL, data)

export const getReferralForGridview = (permission: number[]): Promise<ReferralsGidview> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_GRID_URL, permission)

export const getRefLists = (requestBody: unknown): Promise<CardResponse> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_LIST, requestBody)

export const getPatientMatch = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_PATIENT_MATCH}?patientId=${id}`)

export const savePendingPatient = (data: PendingPatient): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PENDING_PATIENT_SUBMIT, data)

export const referralManualTriggering = (data: { patientId: number }): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](`${REFERRAL_MANUAL_TRIGGERING}?patientId=${data.patientId}`)

export const getCommentLogs = (id: number): Promise<ActivityLogsResponse> =>
  HttpClient[ApiRequestTypes.get](`${COMMENT_LOG_URL}?patientId=${id}`)

export const getSexOffenderList = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${SEX_OFFENDER_LIST_URL}?patientId=${id}`)

export const sexOffenderMatch = (body: SexOffenderMatchBody): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SEX_OFFENDER_MATCH_URL, body)
export const getRefDenialReasons = (): Promise<ReferralsGidview> =>
  HttpClient[ApiRequestTypes.get](REFERRAL_DENIAL_REASONS)

export const getPayerLists = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](GET_PAYER_LIST_URL)

export const waystarMatchRequest = (body: WaystarMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](WAYSTAR_MATCH_REQUEST_URL, body)

export const waystarDelete = (body: WaystarMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](DELETE_WAYSTAR_PROVIDER_URL, body)

export const wayStarSubmit = (patientId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${SUBMIT_WAYSTAR_URL}?patientId=${patientId}`)

export const wayStarValidate = (body: WaystarMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](WAYSTAR_VALIDATE, body)

export const deleteReferral = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](`${DELETE_REFERRAL_URL}?id=${id}`)

export const deleteParentReferral = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](`${DELETE_PARENT_REFERRAL_URL}?id=${id}`)

export const childReferralStatusUpdate = (patientId: number, statusId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CHILD_REFERRAL_STATUS_UPDATE, {
    patientId,
    status: statusId,
  })

export const sendOutBoundCommunication = (data: OutboundMessageRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](OUTBOUNDCOMMUNICATION, data)

export const childReferralApproveReject = (data: ReferralApproveRejectRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CHILD_REFERRAL_APPROVE_REJECT, data)

export const parentReferralStatusUpdate = (
  patientId: number,
  statusLevel: string,
): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PARENT_REFERRAL_STATUS_UPDATE, {
    patientId,
    statusLevel,
  })

export const parentReferralApproveReject = (data: ParentReferralApproveReject): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PARENT_REFERRAL_APPROVE_REJECT, data)

export const parentReferralFacilities = (
  patientId: number,
  statusLevel: string,
): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PARENT_REFERRAL_FACILITIES, {
    patientId,
    statusLevel,
  })

export const updateClinicalReviewStatus = (
  data: SubmitClinicalReviewParentDTO,
): Promise<Envelope> => HttpClient[ApiRequestTypes.post](UPDATE_CLINICAL_REVIEW_STATUS, data)

export const updateClinicalReviewStatusChild = (
  data: SubmitClinicalReviewChildDTO,
): Promise<Envelope> => HttpClient[ApiRequestTypes.post](UPDATE_CLINICAL_REVIEW_STATUS_CHILD, data)

export const getFacilitiesForClinicalReview = (patientId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${CLINICAL_REVIEW_FACILITIES}?patientId=${patientId}`)

export const getRoutes = (): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.get](GET_ALL_ROUTES)
export const getReferralStatus = (permission: number[]): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](GET_ALL_REFERRAL_STATUS, permission)

export const getIntegrationFeatures = (patientId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${INTEGRATION_FEATURES}?patientId=${patientId}`)

export const getMessagesFromSource = (requestBody: MessagesFromSourceRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](MESSAGE_LIST_FROM_SOURCE, requestBody)

export const childReferralStatusRevise = (
  patientFacilityId: number,
  status: number,
): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CHILD_REFERRAL_STATUS_REVERSE, {
    patientFacilityId,
    status,
  })

export const callReviseToClinicalReview = (
  patientFacilityId: number,
  status: number,
  notes: string,
): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](REVERSE_TO_CLINICAL_REVIEW, {
    patientFacilityId,
    status,
    notes,
  })

export const getTodoList = (data: TaskListRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](TASK_LIST_URL, data)

export const updateTaskListStatus = (data: UpdateTaskListStatus): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_TASK_LIST_URL, data)

export const getPccPatientDetails = (orgUuid: string, patientId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_PCC_PATIENT_DEATILS, { orgUuid, patientId })

export const getMostRecentDenialReason = (data: { patientFacilityId: number }): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_MOST_RECENT_DENIAL_REASON, data)

export const getIntegrationSourceStatus = (refId: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_INTEGRATION_SOURCE_STATUS}?childPatientId=${refId}`)

export const getInsuranceDetailsByPatientId = (data: InsuranceDetailReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_INSURANCE_DETAILS_BY_PATIENT_ID, data)
export const callHoldReasons = (): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.get](GET_HOLD_REASONS)

export const callLostReasons = (): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.get](GET_LOST_REASONS)

export const unLockReferral = (data: UnLockReferralData): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_UNLOCK, data)
export const getUnmappedCommunities = (data: string): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](`${UNMAPPED_COMMUNITIES}?sort=${data}`)

export const getMappedCommunities = (data: MappedCommunitiesRequest): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](MAPPED_COMMUNITIES, data)
export const addMappedCommunity = (data: AddMappedCommunityRequest): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](ADD_MAPPED_COMMUNITY, data)
export const updateMappedCommunity = (data: AddMappedCommunityRequest): Promise<DropDownEnvelope> =>
  HttpClient[ApiRequestTypes.post](UPDATE_MAPPED_COMMUNITY, data)
export const getCommunitiesForUnmatched = (
  data: CommunitiesForUnmatchedRequest,
): Promise<DropDownEnvelope> => HttpClient[ApiRequestTypes.post](COMMUNITIES_UNMATCHED, data)
export const addHoldRationale = (data: CreateRationaleReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_HOLD_RATIONALE, data)

export const deleteHoldRationale = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.del](`${DELETE_HOLD_RATIONALE}?id=${id}`)

export const updateHoldRationale = (editholdid: UpdateRationaleReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_HOLD_RATIONALE, editholdid)

export const addLostRationale = (data: CreateRationaleReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_LOST_RATIONALE, data)

export const updateLostRationale = (editholdid: UpdateRationaleReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_LOST_RATIONALE, editholdid)

export const deleteLostRationale = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.del](`${DELETE_LOST_RATIONALE}?id=${id}`)

export const referralRegeneratePDF = (data: { patientId: number }): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](`${REFERRAL_PDF_REGENERATION}?patientId=${data.patientId}`)

export const addDOMODbLabel = (data: CreateDomoDbReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](ADD_DOMO_LABEL, data)

export const updateDOMODbLabel = (editLabel: UpdateDomoDbReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_DOMO_LABEL, editLabel)

export const deleteDOMODbLabel = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.del](`${DELETE_DOMO_LABEL}?id=${id}`)

export const getDOMODashboardList = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](FETCH_DOMO_DASHBOARD_LIST)
export const updateDOMODbPriority = (data: UpdateDomoDbPriorityReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_DASHBOARD_PRIORITY, data)

export const getPverifyPayerLists = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](GET_PVERIFY_PAYER_LIST_URL)

export const pVerifyMatchRequest = (body: PVerifyMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PVERIFY_MATCH_REQUEST_URL, body)

export const pVerifySubmit = (data: PVerifySubmitObject): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](SUBMIT_PVERIFY_URL, data)

export const pVerifyValidate = (body: PVerifyMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](PVERIFY_VALIDATE, body)

export const pVerifyDeletePayer = (body: PVerifyMatchRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](DELETE_PVERIFY_PROVIDER_URL, body)
export const assignedUsersList = (requestBody: AssignedUsersReq): Promise<AssignListResponse> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_ASSIGNED_USERS_LIST, requestBody)

export const deleteLeadAgent = (requestBody: DeleteAssignedUserReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${DELETE_LEAD_AGENTS}?id=${requestBody.id}`)

export const addReferralUsers = (data: AddUsersReq): Promise<AssignedUserResponse> =>
  HttpClient[ApiRequestTypes.post](ADD_REFERRAL_USERS, data)
export const assignedUsersCount = (data: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_ASSIGNED_USERS_COUNT}?patientFacilityId=${data}`)

export const confirmReadAlert = (body: ConfirmReadAlertRequest): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CONFIRM_READ_ALERT, body)
export const fetchReferralUpdates = (requestBody: number): Promise<ReferralUpdatesResData> =>
  HttpClient[ApiRequestTypes.get](`${GET_REFERRAL_UPDATES}?id=${requestBody}`)

export const addSourceHospital = (data: CreateSourceHospitalReq): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](CREATE_SOURCE_HOSPITAL, data)

export const updateSourceHospital = (editSourceHos: UpdateSourceHospital): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.put](UPDATE_SOURCE_HOSPITAL, editSourceHos)

export const deleteSourceHospital = (id: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.del](`${DELETE_SOURCE_HOSPITAL}?id=${id}`)

export const getUnmappedSourceHospitals = (sortBy: string): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_UNMAPPED_SOURCE_HOSPITALS}?sort=${sortBy}`)

export const getMappedSourceHospitals = (data: ReqBody): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](GET_MAPPED_SOURCE_HOSPITALS, data)

export const mapSourceHospital = (data: MapHospitalBody): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.post](MAP_SOURCE_HOSPITAL, data)

export const getAllSourceHospitals = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](GET_ALL_SOURCE_HOSPITALS)

export const getCommunityFaxStatus = (data: CommunityFaxReq): Promise<FaxStatusResponse> =>
  HttpClient[ApiRequestTypes.post](COMMUNITY_FAX, data)

export const getParentFaxStatus = (data: CommunityFaxReq): Promise<FaxStatusResponse> =>
  HttpClient[ApiRequestTypes.post](PARENT_FAX, data)
